// const DOMAIN = "http://localhost:9000";
//const DOMAIN = "https://beehived-stage-api.azurewebsites.net";
const DOMAIN = "https://beehived-prod-api.azurewebsites.net";

export const API = {
  SIGN_UP: DOMAIN + "/auth/signup",
  LOGIN: DOMAIN + "/auth/login",
  SEND_FORGOT_PASSWORD_OTP: DOMAIN + "/auth/sendForgotPasswordOTP",
  VERIFY_FORGOT_PASSWORD_OTP: DOMAIN + "/auth/verifyForgotPasswordOTP",
  UPDATE_PASSWORD: DOMAIN + "/auth/updatePassword",
  VIEW_PROFILE: DOMAIN + "/user/profile",
  VERIFY_OTP: DOMAIN + "/auth/verify",
  RESEND_OTP: DOMAIN + "/auth/resendotp",
  CREATE_BUSINESS_PROFILE: DOMAIN + "/user/profile",
  SKILLS: DOMAIN + "/skill",
  QUALIFICATION: DOMAIN + "/qualification",
  ACCOUNT_VERIFICATION: DOMAIN + "/user/accountVerification",
  MY_PROJECTS: DOMAIN + "/project",
  CREATE_JOB: DOMAIN + "/job",
  GET_JOB_BY_PROJECT_ID: DOMAIN + "/job",
  JOB_FILTERS: DOMAIN + "/job/filter",
  JOB_APPLICATIONS: DOMAIN + "/jobapplication",
  ACCEPTED_JOB_APPLICATIONS: DOMAIN + "/jobapplication/accepted",
  REJECTED_JOB_APPLICATIONS: DOMAIN + "/jobapplication/rejected",
  SHORTLISTED_JOB_APPLICATIONS: DOMAIN + "/jobapplication/shortlisted",
  GET_PROFILE_BY_ID: DOMAIN + "/user/profile",
  UPDATE_JOB_APPLICATIONS: DOMAIN + "/jobapplication",
  GET_APPLIED_ON_JOBS: DOMAIN + "/jobapplication/appliedJobs",
  GET_SAVED_JOBS: DOMAIN + "/savejob",
  GET_PROFESSIONALS: DOMAIN + "/user/getuserswithcompleteprofile/",
  GET_JOB_APPLICATION_STATUS: DOMAIN + "/jobapplication/status",
  SEND_JON_INVITATION: DOMAIN + "/jobinvitation",
  GET_HIRED_JOBS: DOMAIN + "/jobapplication/hiredjob",
  GET_TEAM_MEMBERS: DOMAIN + "/jobapplication/getteammembers?projectId=",
  IS_USER_BUSY: DOMAIN + "/user/isbusy/",
  REVOKE_JOB: DOMAIN + "/jobapplication/revoke",
  TOTAL_JOBS_COMPLETED: DOMAIN + "/jobapplication/totalCompletedJobs",
  NOTIFICATION: DOMAIN + "/usernotification",
  SAVE_TOKEN: DOMAIN + "/pushnotification",
  CHANGE_PASSWORD: DOMAIN + "/auth/changePassword",
  IS_JOB_SAVED: DOMAIN + "/savejob/isSaved",
  UPDATE_JON_INVITATION_STATUS: DOMAIN + "/jobinvitation/updateStatus",
  GET_JON_INVITATION_STATUS: DOMAIN + "/jobinvitation/getStatus",
  PROFESSIONAL_FILTER: DOMAIN + "/user/professionalFilter",
  PROFILE_PICTURE_UPDATE: DOMAIN + "/user/updateProfilePicture",
  CV_UPDATE: DOMAIN + "/user/updateCV",
  GET_REFERRAL_CODE: DOMAIN + "/user/referralCode/",
  IS_INVITED: DOMAIN + "/jobinvitation/isInvitedForJob",
  CUSTOM_PROFILE_SKILLS: DOMAIN + "/customprofileskill",
  MARK_JOB_AS_EXPIRED: DOMAIN + "/job/markJobExpired",
  MARK_JOB_AS_Active: DOMAIN + "/job/markJobActive",
  CUSTOM_JOB_SKILLS: DOMAIN + "/customjobskill",
  READ_ALL_NOTIFICATIONS: DOMAIN + "/usernotification/markAllRead",
  READ_SINGLE_NOTIFICATIONS: DOMAIN + "/usernotification/markRead",
};
